import axios_init from "./axios_init"

export const requests = {
  Auth: {
    login: (data) => axios_init.post("/auth/client/login", data),
    update: (data) => axios_init.post("/client/user/update", data),
    smsLogin: (data) => axios_init.post("/client/user/phone-login", data),
    me: () => axios_init.get("/user/me"),
  },
  Upload: {
    create: (data) => axios_init.post("/client/upload/create", data),
  },

  Users: {
    getList: (page = 1, limit = 10) =>
      axios_init.get(`/client/user/index?page=${page}&limit=${limit}`),
    chargingSessions: (page = 1, limit = 10) =>
      axios_init.get(
        `/client/user-charger/charger-session?page=${page}&limit=${limit}`
      ),
    chargingHistory: (query = "") =>
      axios_init.get(`/client/user-charger/charger-history?${query}`),
    getDetailTop: (id) => axios_init.get(`/client/user/show-top?id=${id}`),
    getDetailBottom: (id) =>
      axios_init.get(`/client/user/show-bottom?id=${id}`),
    getHistory: (id) =>
      axios_init.get(
        `/client/user/account-history?id=${id}&account_history=true`
      ),
  },
  Object: {
    getList: (data) =>
      axios_init.get(`/client/ev/subscription/object-count`, data),
    getListAll: (data) => axios_init.get(`/client/ev/object/index`, data),
    show: (id) => axios_init.get(`/client/ev/object/show?id=${id}`),
    create: (data) => axios_init.post(`/client/ev/object/create`, data),
    update: (data) => axios_init.post(`/client/ev/object/update`, data),
    delete: (data) => axios_init.remove(`/client/ev/object/remove`, data),
  },
  Locations: {
    show: (id) => axios_init.get(`/client/ev/location/show?id=${id}`),
    create: (data) => axios_init.post(`/client/ev/location/create`, data),
    update: (data) => axios_init.post(`/client/ev/location/update`, data),
    delete: (data) => axios_init.remove(`/client/ev/location/remove`, data),
  },
  Documentation: {
    list: (type) => axios_init.get(`/client/documentation/${type}/index`),
    getList: (type = 1) =>
      axios_init.get(`/client/documentation/paperwork/index?type=${type}`),
    create: (data) =>
      axios_init.post(`/client/documentation/paperwork/create`, data),

    delete: (data) =>
      axios_init.remove(`/client/documentation/paperwork/remove`, data),
  },
  FAQ: {
    getList: (type) =>
      axios_init.get(`/client/documentation/question/index?type=${type}`),
    create: (data) =>
      axios_init.post(`/client/documentation/question/create`, data),

    delete: (data) =>
      axios_init.remove(`/client/documentation/question/remove`, data),
  },
  Certificates: {
    getList: (type) =>
      axios_init.get(`/client/documentation/certificate/index?type=${type}`),
    create: (data) =>
      axios_init.post(`/client/documentation/certificate/create`, data),

    delete: (data) =>
      axios_init.remove(`/client/documentation/certificate/remove`, data),
  },
  ObjectBanner: {
    show: (id) => axios_init.get(`/client/ev/banner/show?id=${id}`),
    create: (data) => axios_init.post(`/client/ev/banner/create`, data),
    update: (data) => axios_init.post(`/client/ev/banner/update`, data),
    delete: (data) => axios_init.remove(`/client/ev/banner/remove`, data),
  },
  Reasons: {
    getList: (data) => axios_init.get(`/client/setting/reason/index`, data),
    show: (data) => axios_init.post(`/client/setting/reason/show`, data),
    create: (data) => axios_init.post(`/client/setting/reason/create`, data),
    update: (data) => axios_init.post(`/client/setting/reason/update`, data),
    delete: (data) => axios_init.remove(`/client/setting/reason/remove`, data),
  },
  Facilities: {
    getList: (data) => axios_init.get(`/client/setting/facility/index`, data),
    create: (data) => axios_init.post(`/client/setting/facility/create`, data),
    update: (data) => axios_init.post(`/client/setting/facility/update`, data),
    delete: (data) =>
      axios_init.remove(`/client/setting/facility/remove`, data),
  },
  Cities: {
    getList: (data) => axios_init.get(`/client/setting/city/index`, data),
    show: (data) => axios_init.post(`/client/setting/city/show`, data),
    create: (data) => axios_init.post(`/client/setting/city/create`, data),
    update: (data) => axios_init.post(`/client/setting/city/update`, data),
    delete: (data) => axios_init.remove(`/client/setting/city/remove`, data),
  },
  Colors: {
    getList: (data) => axios_init.get(`/client/car/color/list`, data),
    create: (data) => axios_init.post(`/client/car/color/create`, data),
    update: (data) => axios_init.post(`/client/car/color/update`, data),
    delete: (data) => axios_init.remove(`/client/car/color/remove`, data),
  },
  Subscription: {
    getList: (data) =>
      axios_init.get(`/client/setting/subscription/index`, data),
    create: (data) =>
      axios_init.post(`/client/setting/subscription/create`, data),
    update: (data) =>
      axios_init.post(`/client/setting/subscription/update`, data),
    delete: (data) =>
      axios_init.remove(`/client/setting/subscription/remove`, data),
  },
  Brands: {
    getList: (data) => axios_init.get(`/client/car/brand/index`, data),
    getListAll: (data) => axios_init.get(`/client/car/brand/list`, data),
    create: (data) => axios_init.post(`/client/car/brand/create`, data),
    update: (data) => axios_init.post(`/client/car/brand/update`, data),
    delete: (data) => axios_init.remove(`/client/car/brand/remove`, data),
  },
  Courier: {
    getList: (data) => axios_init.get(`/client/courier/index`, data),
    create: (data) => axios_init.post(`/client/user/create`, data),
    update: (data) => axios_init.post(`/client/courier/update`, data),
    delete: (data) => axios_init.remove(`/client/courier/remove`, data),
  },
  Category: {
    getList: (data) => axios_init.get(`/client/ev/category/index`, data),
    create: (data) => axios_init.post(`/client/ev/category/create`, data),
    update: (data) => axios_init.post(`/client/ev/category/update`, data),
    delete: (data) => axios_init.remove(`/client/ev/category/remove`, data),
  },
  ServiceType: {
    getList: (data) => axios_init.get(`/client/sos/service-type/index`, data),
    create: (data) => axios_init.post(`/client/sos/service-type/create`, data),
    update: (data) => axios_init.post(`/client/sos/service-type/update`, data),
    delete: (data) =>
      axios_init.remove(`/client/sos/service-type/remove`, data),
  },
  ServiceTarif: {
    getList: (data) => axios_init.get(`/client/sos/service-tarif/index`, data),
    create: (data) => axios_init.post(`/client/sos/service-tarif/create`, data),
    update: (data) => axios_init.post(`/client/sos/service-tarif/update`, data),
    delete: (data) =>
      axios_init.remove(`/client/sos/service-tarif/remove`, data),
  },
  LiveMap: {
    mapList: (page = 1, limit = 10) =>
      axios_init.get(
        `/client/station/short-statuses?page=${page}&limit=${limit}&latitude=62.817259&longitude=43.969749`
      ),
    statuses: () => axios_init.get(`client/box/statuses`),
  },
  Stations: {
    getList: (pagination) =>
      axios_init.get(
        `/client/station/index?page=${pagination?.page || 1}&limit=${
          pagination?.limit || 10
        }&latitude=62.817259&longitude=43.969749`
      ),
    getDetail: (id) =>
      axios_init.get(
        `/client/station/show?id=${id}&latitude=41.344982&longitude=69.208676`
      ),
    create: (data) => axios_init.post("/client/station/create", data),
    update: (data) => axios_init.post("/client/station/update", data),
    owner: (data) => axios_init.get("/client/owner/index", data),
    chargerTypes: () => axios_init.get("/client/charger-type/index"),
  },
  Boxes: {
    getList: (pagination) =>
      axios_init.get(
        `/client/box/index?page=${pagination?.page || 1}&limit=${
          pagination?.limit || 10
        }`
      ),
    getDetail: (id) =>
      axios_init.get(
        `/client/box/show?id=${id}&latitude=41.344982&longitude=69.208676`
      ),
    create: (data) => axios_init.post("/client/box/create", data),
    update: (data) => axios_init.post("/client/box/update", data),
    delete: (data) => axios_init.post("/client/box/delete", data),
  },
  Payment: {
    getList: (data) =>
      axios_init.get(
        `/client/payment/list?type=${data?.type || 1}&start_date=${
          data?.start_date || "21.05.2024"
        }&end_date=${data?.end_date || "21.05.2024"}`
      ),
    getDetail: (data, id) =>
      axios_init.get(
        `/client/payment/show?id=${id}&type=${data?.type || 1}&start_date=${
          data?.start_date || "21.05.2024"
        }&end_date=${data?.end_date || "21.05.2024"}&page=${data?.page}&limit=${
          data?.limit
        }`
      ),
  },
  Cars: {
    getListBrand: () => axios_init.get(`/client/car/brand/list`),
    getListBrandCars: (pagination) =>
      axios_init.get(
        `/client/car/brand/index?page=${pagination?.page || 1}&limit=${
          pagination?.limit || 10
        }`
      ),
    getModel: (key, value) =>
      axios_init.get(`/client/car/model/index?${key}=${value}`),

    modelShow: (id) => axios_init.get(`/client/car/model/show?id=${id}`),
    carShow: (id) => axios_init.get(`/client/car/show?id=${id}`),
    updateUserCar: (data) =>
      axios_init.post(`/client/car/user-car/update`, data),
    updateModel: (data) => axios_init.post(`/client/car/model/update`, data),
  },
}
